import { Injectable } from '@angular/core';

import { BaseApi } from './base/base.api';
import { Role } from '@/models/role.model';
import { Token } from '@/models/token.model';
import { PostUserResponse } from '@/models/user.model';
import {
  CreateUser,
  NewPassword,
  RegisterUser,
} from '@/models/authenticate.model';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthenticateApi extends BaseApi {
  getRoles(): Promise<Role[]> {
    return this.get(`${this.url}/authenticate/roles`);
  }

  login(email: string, password: string): Promise<Token> {
    const post = {
      email,
      password,
    };

    return this.post(`${this.url}/authenticate/login-backoffice`, post);
  }

  register(form: CreateUser): Promise<Token> {
    return this.post(`${this.url}/authenticate/register`, form);
  }

  registerUser(form: RegisterUser): Promise<PostUserResponse> {
    return this.post(`${this.url}/authenticate/register/user`, form);
  }

  deleteUser(id: string): Promise<any> {
    return this.delete(`${this.url}/users/${id}`);
  }

  resendEmailConfirmation(email: string): Promise<void> {
    return this.post(`${this.url}/authenticate/resend-email-confirmation`, {
      email,
    });
  }

  forgotPassword(email: string): Promise<void> {
    return this.post(`${this.url}/authenticate/forgot-password`, {
      email,
    });
  }

  newPassword(form: NewPassword): Promise<void> {
    return this.post(`${this.url}/authenticate/new-password`, form);
  }

  generateNewTemporaryPassword(email: string): Promise<void> {
    return this.post(
      `${this.url}/authenticate/generate-new-temporary-password`,
      { email },
    );
  }

  confirmEmail(userId: string, token: string): Promise<void> {
    return this.post(`${this.url}/authenticate/confirm-email`, {
      userId,
      token,
    });
  }

  logout(): Promise<null> {
    return this.post(`${this.url}/authenticate/logout`, {});
  }
}
